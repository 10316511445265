// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
    production: false,
    hmr: false,
    type: "Staging",
    apiUrl: "https://test.api.goadroit.com/v1",
    webUrl: "https://test.provider.goadroit.com/",
    rideWaypointsEtaRootCollection: "test_jobs",
    client_id: '783896173032-91afo3riksqg88mm8ffh2cjns3f0rtl6.apps.googleusercontent.com',
    microsoft_client_id: 'ced88beb-2017-4469-acda-4a105c49a819',
    redirect_uri: 'https://test.goadroit.com',
    logout_redirect_uri: 'https://test.api.goadroit.com/logout',
    clientUrl: "https://test.app.goadroit.com/",
};
/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
